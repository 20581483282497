import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import { LoginComponent } from './auth/login/login.component';
import { IntermediariaComponent } from './auth/intermediaria/intermediaria.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginGuard } from './auth/service/login.guard';
import { ContratarComponent } from './auth/contratar/contratar.component';

const routes: Routes = [

    {path: `login`, component: LoginComponent, canActivate: [LoginGuard]},
    {path: `selecao-empresa`, component: IntermediariaComponent},
    {path: `home`, component: HomeComponent},
    {path: `contratar`, component: ContratarComponent},
    {path: '',   redirectTo: '/login', pathMatch: 'full'}

]    

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
