<div class="topbar">
    <div class="topbar-main">
        <a href="#" id="menu-button" (click)="app.onMenuButtonClick($event)">
            <span class="fa fa-bars"></span>
        </a>
        <div class="app-name" style="margin-top: 10px;margin-left: 25px;">
            <a href="/">toqWEB</a>
        </div>

        <a href="#" id="user-display" (click)="app.onTopbarMenuButtonClick($event)" style="margin-right: 160px;">
            <span class="username">{{usuario}}</span>
            <span class="userrole">{{empresa}}</span>
            <img alt="main logo" src="assets/layout/images/avatar.png">
        </a>

        <ul id="topbar-menu" class="fadeInDown animated" [ngClass]="{'topbar-menu-visible': app.topbarMenuActive}">


            <li #usuarios [ngClass]="{'menuitem-active':app.activeTopbarItem === usuarios}">
                <a href="#" (click)="app.onTopbarItemClick($event, usuarios)">
                    <i class="topbar-icon fa fa-fw fa-bell-o"></i>
                    <span class="topbar-item-name">Usuarios</span>
                </a>
                <ul>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event, '/usuario')">
                            <i class="fa fa-fw fa-tasks"></i>
                            <span>usuarios</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="fa fa-fw fa-tasks"></i>
                            <span>Perfil</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="fa fa-fw fa-tasks"></i>
                            <span>Permissões</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.alterarSenha()">
                            <i class="fa fa-fw fa-tasks"></i>
                            <span>Alterar Senha</span>
                        </a>
                    </li>
                </ul>
            </li>
            
            <li role="menuitem">
                <a href="#" (click)="app.deslogar()">
                    <i class="fa fa-fw fa-sign-out"></i>
                    <span>Sair</span>
                </a>
            </li>

        </ul>

    </div>

    <app-menu></app-menu>

</div>
