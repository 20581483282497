import { Injectable } from '@angular/core';
import {Location} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private _location: Location) { }

  backHistory() {
      this._location.back();
  }

}
