import {Validators} from "@angular/forms";

export class ClienteToqsys {
    ID: number = 0;
    ID_REVENDA: number = 0;
    STATUS: string = '';
    RAZAO_SOCIAL: string = '';
    NOME_FANTASIA: string = '';
    CPF_CNPJ: string = '';
    IE: string = '';
    VERSAO_SISTEMA: string = '';
    URI: string = '';
    NOME_BANCO: string = '';
    QTD_EMPRESAS_GRUPO: number = 0;
    SENHA_MASTER: string = '';
    ENDERECO: string = '';
    COMPLEMENTO: string = '';
    NUMERO: string = '';
    BAIRRO: string = '';
    CEP: string = '';
    CIDADE: string = '';
    UF: string = '';
    EMAIL: string = '';
    TELEFONE: string = '';
    CELULAR: string = '';
    CELULAR_CONTATO: string = '';
    CONTATO: string = '';
    DATA_INI: any = ''
    DATA_FIN: any = ''
    VALIDADE: string = '';
    DATA_ATIVACAO: any = ''
    DATA_BLOQUEIO: any = ''
    DATA_DESATIVACAO: any = ''
    REGIME_TRIBUTARIO: number = 0;
    QTD_ESTACOES: number = 0;
    QTD_CAIXAS: number = 0;

    static datas() {
        return ['DATA_INI', 'DATA_FIN', 'DATA_ATIVACAO', 'DATA_BLOQUEIO', 'DATA_DESATIVACAO', ]
    }

    static validacoes() {
        return [
            {campo: 'CONTATO', nome: 'Contato', validation: Validators.compose([Validators.required])},
            {campo: 'EMAIL', nome: 'Email', validation: Validators.compose([Validators.required])},
            {campo: 'SENHA_MASTER', nome: 'Senha Master', validation: Validators.compose([Validators.required])},
        ]
    }

    static mascaras() {
        return ['TELEFONE', 'CELULAR', 'CELULAR_CONTATO', 'CPF_CNPJ', 'CEP']
    }

}
