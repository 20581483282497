import {Component, ContentChild, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ClienteToqsys} from "../../model/cliente-toqsys.model";
import {DadosDefaultService} from "../../services/dados-default.service";
import {NetworkService} from "../../services/network.service";
import {MessageService} from "primeng/api";
import {Endereco, NgxViacepService} from "@brunoc/ngx-viacep";
import {Formulario} from "../../controller/Formulario";
import {Util} from "../../controller/Util";
import {API_AUTH} from "../../controller/staticValues";
import {NavigationService} from "../service/navigation.service";

@Component({
  selector: 'app-contratar',
  templateUrl: './contratar.component.html',
  styleUrls: ['./contratar.component.css']
})
export class ContratarComponent implements OnInit {

    @Input() modalVisible = false;
    @Output() dadosSalvos = new EventEmitter()
    @Output() closeModal = new EventEmitter()
    @ViewChild('cep', {static: false}) inputCep2: any
    @ContentChild('cep', {static: false}) inputCep: any
    form: FormGroup
    entObj = new ClienteToqsys()
    modalCepVisible = false;
    arr = Array(30).fill(0)
    selectDiaVencimento = this.arr.map((v, i) => ({label: (i + 1).toString(), value: (i + 1)}));
    selectPlano = [
        {label: 'Mensal', value: 'm'},
        {label: 'Anual', value: 'a'},
    ];

    selectRevenda = []


    constructor(private fb: FormBuilder, private dadosDefault: DadosDefaultService, private networkService: NetworkService, private messageService: MessageService, private viaCep: NgxViacepService, private navigationService: NavigationService) {

        this.form = this.fb.group({
            primeiraEtapa: this.fb.group({
                CPF_CNPJ: ['', Validators.compose([Validators.required, Validators.minLength(11), Validators.maxLength(18)])],
                RAZAO_SOCIAL: ['', Validators.required],
                CONTATO: ['', Validators.required],
                EMAIL: ['', Validators.compose([Validators.required, Validators.email])],
            }),
            segundaEtapa: this.fb.group({
               diaVencimento: ['', Validators.required],
               plano: ['', Validators.required],
               economize: [''],
            }),
            terceiraEtapa: this.fb.group({
                SENHA_MASTER: ['', Validators.required],
                RepetirSenha: ['', Validators.required],
                TELEFONE: [''],
                CELULAR: [''],
                CEP: ['', Validators.required],
                ENDERECO: [''],
                NUMERO: [''],
                BAIRRO: [''],
                UF: [''],
                CIDADE: [''],
                COMPLEMENTO: [''],
                ID_REVENDA: ['', Validators.required],
            }),
            quartaEtapa: this.fb.group({
               concordaTermos: [''],
            }),

        })
    }

    ngOnInit() {
        this.dadosDefault.contratar().subscribe(v => {
            this.selectRevenda = v[0]
        })
    }

    fecharModal() {
        this.closeModal.emit(false)
    }

    confirmar() {

        const {concordaTermos} = Object.assign({}, this.form.get('quartaEtapa').value)
        const {RepetirSenha} = Object.assign({}, this.form.get('terceiraEtapa').value)

        const {primeiraEtapa, segundaEtapa, terceiraEtapa} = this.form.value

        const v = {...primeiraEtapa, ...terceiraEtapa}
        delete v.RepetirSenha

        if (!concordaTermos || !concordaTermos.length || !concordaTermos[0]) {
            this.messageService.add(Util.pushErrorMsg('Voce deve concordar com os termos primeiro'))
            return;
        }
        if (RepetirSenha !== v.SENHA_MASTER) {
            this.messageService.add(Util.pushErrorMsg('As senhas devem ser iguais!'))
            return;
        }

        this.networkService.salvarPost(API_AUTH, `implante/Liberador/emp/ImplantaEmpresa`, Formulario.parseForm(this.entObj, v, null, ClienteToqsys.mascaras())).subscribe(value => {
            this.navigationService.backHistory();
        })
    }

    verificaCepValido(event) {
        if ((event.key === 'Enter' || event.type === 'blur') && this.form.get('terceiraEtapa').get('CEP').value !== null) {
            let cep = this.form.get('terceiraEtapa').get('CEP').value.toString().match(/\d/g)
            if (cep === null) return
            cep = cep.join('');
            if (cep.length === 8) {
                this.viaCep.buscarPorCep(cep)
                    .then((endereco: Endereco) => {
                        this.form.get('terceiraEtapa').get('ENDERECO').setValue(endereco.logradouro);
                        this.form.get('terceiraEtapa').get('COMPLEMENTO').setValue(endereco.complemento);
                        this.form.get('terceiraEtapa').get('BAIRRO').setValue(endereco.bairro);
                        this.form.get('terceiraEtapa').get('CIDADE').setValue(endereco.localidade);
                        this.form.get('terceiraEtapa').get('UF').setValue(endereco.uf)
                    })
            }
        }
    }

    buscaCnpj(e: any) {
        let cnpj = this.form.get('primeiraEtapa').get('CPF_CNPJ').value.toString().match(/\d/g);
        if (cnpj === null) return;
        cnpj = cnpj.join('')
        if (cnpj.length === 14) {
            this.dadosDefault.buscarCnpj(cnpj).subscribe((v: any) => {
                    this.form.get('primeiraEtapa').get('RAZAO_SOCIAL').setValue(v['nome']);
                    let cep = v['cep']
                    if(cep === null) return ''
                    cep = cep.replace(/\./, '')
                    cep = cep.replace(/-/, '')
                    this.form.get('terceiraEtapa').get('CEP').setValue(cep);
                    this.form.get('terceiraEtapa').get('ENDERECO').setValue(v['logradouro']);
                    this.form.get('terceiraEtapa').get('NUMERO').setValue(v['numero']);
                    this.form.get('terceiraEtapa').get('COMPLEMENTO').setValue(v['complemento']);
                    this.form.get('terceiraEtapa').get('BAIRRO').setValue(v['bairro']);
                    this.form.get('terceiraEtapa').get('CIDADE').setValue(v['municipio']);
                    this.form.get('terceiraEtapa').get('UF').setValue(v['uf'])

                    if (v.status && v.status === 'ERROR') {
                        this.messageService.add(Util.pushErrorMsg(v.message))
                    }
                },
                e => this.messageService.add(Util.pushErrorMsg('CNPJ nao encontrado')))
        }

    }

}
