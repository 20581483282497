<div *ngIf="usuarioAutenticado" class="layout-wrapper" [ngClass]="{'layout-menu-horizontal': menuMode === 'horizontal',
                                        'layout-menu-popup': menuMode === 'popup',
                                        'layout-menu-overlay': menuMode === 'overlay',
                                        'layout-menu-static': menuMode === 'static',
                                        'layout-menu-static-inactive': (menuMode == 'static' && menuActive === false)}"
     (click)="onLayoutClick()">
    <app-topbar></app-topbar>

    <div class="layout-content">
        <router-outlet></router-outlet>
    </div>

</div>

<div *ngIf="!usuarioAutenticado">
    <router-outlet></router-outlet>
</div>

<div *ngIf="exibirLoader | async" style="position: fixed; width: 100vw; height: 100vh; top: 0; left: 0; z-index: 100; display: flex; align-items: center; justify-content: center; background-color: rgba(255, 255, 255, .4);">
    <p-progressSpinner></p-progressSpinner>
</div>

<div *ngIf="exibirLoaderNetwork | async" style="position: fixed; width: 100vw; height: 100vh; top: 0; left: 0; z-index: 100; display: flex; align-items: center; justify-content: center; background-color: rgba(255, 255, 255, .4);">
    <p-progressSpinner></p-progressSpinner>
</div>

<p-toast position="bottom-right"></p-toast>

<!--<app-alterar-senha [modalVisible]="modalAlterarSenhaVisible" (closeModal)="modalAlterarSenhaVisible = false"></app-alterar-senha>-->
