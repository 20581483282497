import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "./auth/service/auth.service";
import {NavigationEnd, Router} from "@angular/router";
import {DadosDefaultService} from "./services/dados-default.service";
import {NetworkService} from "./services/network.service";
import {getUrlCad,} from "./controller/staticValues";
import {MessageService} from "primeng/api";
import {Subscription} from "rxjs";
import {map} from "rxjs/operators";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    public menuMode = 'popup';

    public menuActive = true;

    public topbarMenuActive = false;

    activeTopbarItem: Element;

    menuClick: boolean;

    menuButtonClick: boolean;

    topbarMenuButtonClick: boolean;

    resetMenu: boolean;

    menuHoverActive: boolean;

    public usuarioAutenticado = false

    $usuarioLogadoSubscribe: Subscription;
    $parametrosSubscribe: Subscription;
    modalAlterarSenhaVisible = false;
    exibirLoader = this.dadosDefault.exibirLoader
    exibirLoaderNetwork = this.networkService.exibirLoader

    constructor(private authService: AuthService, private router: Router, private dadosDefault: DadosDefaultService, private networkService: NetworkService, private messageService: MessageService) {
    }

    ngOnInit() {
        this.router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                if (e.url === '/home') {
                    if (!sessionStorage.getItem('postergar')) {
                        this.router.navigate(['/home'], {replaceUrl: true})
                    }
                }
            }
        })

        window.addEventListener("beforeunload", function (e) {
            sessionStorage.setItem('time', new Date().toString());

        });

        this.$usuarioLogadoSubscribe = this.authService.isUsuarioLogado().subscribe(res => {
            console.log(res);
            this.usuarioAutenticado = res
        })
    }

    onMenuButtonClick(event: Event) {
        this.menuButtonClick = true;
        this.menuActive = !this.menuActive;
        event.preventDefault();
    }

    onTopbarMenuButtonClick(event: Event) {
        this.topbarMenuButtonClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;
        event.preventDefault();
    }

    onTopbarItemClick(event: Event, item: Element, route?) {
        this.topbarMenuButtonClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        } else {
            this.activeTopbarItem = item;
        }
        event.preventDefault();
    }

    onTopbarSubItemClick(event, route?) {
        event.preventDefault();
        if (route && typeof route === 'string') {
            if (route.length === 1) {
                switch (route) {
                    case 'h':
                        this.menuMode = 'horizontal'
                        break;
                    case 's':
                        this.menuMode = 'static'
                        break;
                    case 'o':
                        this.menuMode = 'overlay'
                        break;
                    case 'p':
                        this.menuMode = 'popup'
                        break;
                }
            } else {
                this.router.navigate([route])
            }
        }
    }

    deslogar() {
        this.usuarioAutenticado = false
        sessionStorage.clear()
        this.router.navigate(['/login'])
    }

    onLayoutClick() {
        if (!this.menuButtonClick && !this.menuClick) {
            if (this.menuMode === 'horizontal') {
                this.resetMenu = true;
            }

            if (this.isMobile() || this.menuMode === 'overlay' || this.menuMode === 'popup') {
                this.menuActive = false;
            }

            this.menuHoverActive = false;
        }

        if (!this.topbarMenuButtonClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }

        this.menuButtonClick = false;
        this.menuClick = false;
        this.topbarMenuButtonClick = false;
    }

    onMenuClick() {
        this.menuClick = true;
        this.resetMenu = false;
    }

    isMobile() {
        return window.innerWidth < 1025;
    }

    isHorizontal() {
        return this.menuMode === 'horizontal';
    }

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    ngOnDestroy() {
        if (this.$usuarioLogadoSubscribe) this.$usuarioLogadoSubscribe.unsubscribe()
        if (this.$parametrosSubscribe) this.$parametrosSubscribe.unsubscribe()
    }

    alterarSenha() {
        this.modalAlterarSenhaVisible = true
    }
}
