import {Injectable} from '@angular/core';
import {getUrlCad, URL_BASE, getUrlPcp, getUrlVenda, API_AUTH} from "../controller/staticValues";
import {debounceTime, map} from "rxjs/operators";
import {catchError} from "rxjs/internal/operators/catchError";
import {forkJoin} from "rxjs/internal/observable/forkJoin";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {of} from "rxjs/internal/observable/of";
import {BehaviorSubject, Observable, Subject, throwError} from "rxjs";
import {Util} from "../controller/Util";

@Injectable({
    providedIn: 'root'
})
export class DadosDefaultService {

    constructor(private http: HttpClient) {}

    public exibirLoader = new BehaviorSubject(false);
    private modalSubject = new Subject();
    public modal: Observable<any> = this.modalSubject.asObservable();
    private dadosAlterarItem = new Subject();
    public alterarItem: Observable<any> = this.dadosAlterarItem.asObservable();
    public dadosAdicionarEndereco = new Subject();
    public adicionarEndereco: Observable<any> = this.dadosAdicionarEndereco.asObservable();
    public dadosAdicionarContato = new Subject();
    public adicionarContato: Observable<any> = this.dadosAdicionarContato.asObservable();
    public setParametros = new BehaviorSubject(null);
    public parametros: Observable<any> = this.setParametros.asObservable();
    public routeParams = new BehaviorSubject(0);
    public dadosRota: Observable<any> = this.routeParams.asObservable();
    public conciliacaoParams = new BehaviorSubject(null);
    public conciliacao: Observable<any> = this.conciliacaoParams.asObservable();
    public conciliarParcelaParams = new BehaviorSubject(null);
    public conciliarParcela: Observable<any> = this.conciliarParcelaParams.asObservable();
    public conciliarContabilParams = new BehaviorSubject(null);
    public conciliarContabil: Observable<any> = this.conciliarContabilParams.asObservable();

    enviarItemParaAlterar(value) {
        this.dadosAlterarItem.next(value)
    }

    closeModal(hash) {
        this.modalSubject.next(hash)
    }

    listaModais: string[] = [];

    buscarProduto(text) {
        return this.http.post(`${getUrlCad()}/produtointerface/buscarproduto`, text).pipe(map(v => v['value']))
    }

    buscarCliente(text) {
        return this.http.post(`${getUrlCad()}/PessoaInterface/GetPessoaEssencial`, text)
    }

    verificaNcmValido(value) {
        let ncm = {CodigoNcm: value};
        return this.http.post(`${URL_BASE}/NcmInterface/BuscarNcm`, ncm).pipe(catchError(this.errorHandler))
    }

    verificaCestValido(value) {
        return this.http.get(`${URL_BASE}/cest?$filter=(CodigoCest eq '${value}')`).pipe(catchError(this.errorHandler))
    }

    public buscarCnpj(cnpj: string) {
        return this.http.get(`https://bpoymh2e3b.execute-api.us-east-1.amazonaws.com/prod/consulta-cnpj/${cnpj}`)
    }

    pessoa() {
        let situacaoPessoa = this.http.get(`${getUrlCad()}/situacaopessoa`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let operacaoFiscal = this.http.get(`${getUrlCad()}/operacaofiscal`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let naturezaFinanceira = this.http.get(`${getUrlCad()}/naturezafinanceira?$filter=Sintetico eq false`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.CodControle}))), catchError(this.errorHandlerDefaultValues));
        let vendedor = this.http.get(`${getUrlCad()}/vendedor`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let tipoendereco = this.http.get(`${getUrlCad()}/tipoendereco`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let contaContabil = this.http.get(`${getUrlCad()}/planocontas?$filter=Sintetico eq false`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let tabelaPreco = this.http.get(`${getUrlCad()}/tabelapreco`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let pais = this.http.get(`${URL_BASE}/paises`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Pais), value: Util.toNumber(v.Ibge)}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([situacaoPessoa, operacaoFiscal, naturezaFinanceira, vendedor, tipoendereco, contaContabil, tabelaPreco, pais])
    }

    produto() {
        let grupoTributario = this.http.get(`${getUrlCad()}/grupotributario`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let unidadeProduto = this.http.get(`${getUrlCad()}/unidadeproduto`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Nome),
            value: v.Id
        }))), catchError(this.errorHandlerDefaultValues));
        let secao = this.http.get(`${getUrlCad()}/secao`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Nome),
            value: v.Id
        }))), catchError(this.errorHandlerDefaultValues));
        let categoria = this.http.get(`${getUrlCad()}/categoria`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Nome),
            value: v.Id
        }))), catchError(this.errorHandlerDefaultValues));
        let subcategoria = this.http.get(`${getUrlCad()}/subcategoria`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Nome),
            value: v.Id
        }))), catchError(this.errorHandlerDefaultValues));
        let marca = this.http.get(`${getUrlCad()}/marca`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Nome),
            value: v.Id
        }))), catchError(this.errorHandlerDefaultValues));
        let grupo = this.http.get(`${getUrlCad()}/grupo`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Id
        }))), catchError(this.errorHandlerDefaultValues));
        let impostoIcms = this.http.get(`${getUrlCad()}/impostoicms`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Id
        }))), catchError(this.errorHandlerDefaultValues));
        let setor = this.http.get(`${getUrlCad()}/setor`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Nome),
            value: v.Id
        }))), catchError(this.errorHandlerDefaultValues));
        let unidcompra = this.http.get(`${getUrlCad()}/unidadeproduto`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Nome),
            value: v.Id
        }))), catchError(this.errorHandlerDefaultValues));
        let cstIpi = this.http.get(`${URL_BASE}/cstipi`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Codigo
        }))), catchError(this.errorHandlerDefaultValues));
        let cstCofins = this.http.get(`${URL_BASE}/cstcofins`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Codigo
        }))), catchError(this.errorHandlerDefaultValues));
        let cstPis = this.http.get(`${URL_BASE}/cstpis`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Codigo
        }))), catchError(this.errorHandlerDefaultValues));
        let cstAIcms = this.http.get(`${URL_BASE}/cstaicms`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Cst
        }))), catchError(this.errorHandlerDefaultValues));
        let cstBIcms = this.http.get(`${URL_BASE}/cstbicms`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Cst
        }))), catchError(this.errorHandlerDefaultValues));
        let cest = this.http.get(`${URL_BASE}/cest`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.CodigoCest}))), catchError(this.errorHandlerDefaultValues));

        return forkJoin([grupoTributario, unidadeProduto, secao, categoria, subcategoria, marca, grupo, impostoIcms, setor, unidcompra,
            cstIpi, cstCofins, cstPis, cstAIcms, cstBIcms])
    }

    nfe() {
        let operacaoFiscal = this.http.get(`${getUrlCad()}/operacaofiscal?$expand=IdCfop`).pipe(map((res: Response) => res['value']), catchError(this.errorHandlerDefaultValues));
        let vendedor = this.http.get(`${getUrlCad()}/vendedor`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let tabelaPreco = this.http.get(`${getUrlCad()}/tabelapreco`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let cstIpi = this.http.get(`${URL_BASE}/cstipi`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo}))), catchError(this.errorHandlerDefaultValues));
        let cstIcms = this.http.get(`${URL_BASE}/cstbicms`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Cst}))), catchError(this.errorHandlerDefaultValues));
        let cstCofins = this.http.get(`${URL_BASE}/cstcofins`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo}))), catchError(this.errorHandlerDefaultValues));
        let cstPis = this.http.get(`${URL_BASE}/cstpis`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo}))), catchError(this.errorHandlerDefaultValues));
        let condicoesPagamento = this.http.get(`${getUrlCad()}/condicoespagamento`).pipe(map((res: Response) => res['value']));
        let bandeira = this.http.get(`${getUrlCad()}/bandeira`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let csosn = this.http.get(`${URL_BASE}/csosn`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo.toString()}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([operacaoFiscal, vendedor, tabelaPreco, cstIpi, cstIcms, cstCofins, cstPis, condicoesPagamento, bandeira, csosn])
    }

    importarXmlNaoVinculado() {
        let grupo = this.http.get(`${getUrlCad()}/grupo`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let impostoIcms = this.http.get(`${getUrlCad()}/impostoicms`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let grupoTributario = this.http.get(`${getUrlCad()}/grupotributario`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let operacaoFiscal = this.http.get(`${getUrlCad()}/operacaofiscal`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let cfop = this.http.get(`${getUrlCad()}/cfop?$filter=(TipoOperacao eq 'E' and IndNfe eq 1)`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Cfop}))), catchError(this.errorHandlerDefaultValues));
        let naturezaFinanceira = this.http.get(`${getUrlCad()}/naturezafinanceira?$filter=Sintetico eq false and ApareceEntrada eq true`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Id
        }))));
        return forkJoin([grupo, impostoIcms, grupoTributario, operacaoFiscal, cfop, naturezaFinanceira])
    }

    modalCadastrarProdutoFromNfeDetalhe() {
        let grupo = this.http.get(`${getUrlCad()}/grupo`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let impostoIcms = this.http.get(`${getUrlCad()}/impostoicms`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([grupo, impostoIcms])
    }

    operacaoFiscal() {
        let cfop = this.http.get(`${URL_BASE}/cfop`).pipe(map((res: Response) => res['value']), catchError(this.errorHandlerDefaultValues));
        let tipoFiscal = this.http.get(`${URL_BASE}/nfeTipoFiscal`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Tipo}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([cfop, tipoFiscal])
    }

    ncm() {
        let cstIpi = this.http.get(`${URL_BASE}/cstipi`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Codigo
        }))), catchError(this.errorHandlerDefaultValues));
        let cstCofins = this.http.get(`${URL_BASE}/cstcofins`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Codigo
        }))), catchError(this.errorHandlerDefaultValues));
        let cstPis = this.http.get(`${URL_BASE}/cstpis`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Codigo
        }))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([cstIpi, cstCofins, cstPis])
    }

    ncmContador() {
        let cstIpi = this.http.get(`${URL_BASE}/cstipi`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Codigo
        }))), catchError(this.errorHandlerDefaultValues));
        let cstCofins = this.http.get(`${URL_BASE}/cstcofins`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Codigo
        }))), catchError(this.errorHandlerDefaultValues));
        let cstPis = this.http.get(`${URL_BASE}/cstpis`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Codigo
        }))), catchError(this.errorHandlerDefaultValues));
        let cstBIcms = this.http.get(`${URL_BASE}/cstbicms`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Cst
        }))), catchError(this.errorHandlerDefaultValues));
        let unidadeTributaria = this.http.get(`${URL_BASE}/unidadetributaria`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Nome
        }))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([cstIpi, cstCofins, cstPis, cstBIcms, unidadeTributaria])
    }

    grupoBem() {
        let planocontas = this.http.get(`${getUrlCad()}/planocontas?$filter=Sintetico eq false`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo}))));
        return forkJoin([planocontas, ])
    }

    contratar() {
        let revenda = this.http.get(`${API_AUTH}/auth/revenda`).pipe(map((res: Response) => res['value'].map(v => ({label: v.NOME, value: v.ID}))));
        return forkJoin([revenda, ])
    }

    mdfe() {
        let osEquipamento = this.http.get(`${getUrlCad()}/OsEquipamento`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        return forkJoin([osEquipamento])
    }

    patrimBem() {
        let setor = this.http.get(`${getUrlCad()}/setor`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let grupoBem = this.http.get(`${getUrlCad()}/patrimgrupobem`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let bemPrincipal = this.http.get(`${getUrlCad()}/patrimbem`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let funcaoImobilizado = this.http.get(`${getUrlCad()}/prodfuncaoimobilizado`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([setor, grupoBem, bemPrincipal, funcaoImobilizado])
    }

    caixaPdv() {
        let contacaixa = this.http.get(`${getUrlCad()}/contacaixa`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let nfeindicadorpresenca = this.http.get(`${getUrlCad()}/nfeindicadorpresenca`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: Number(v.Codigo)}))));
        let nfemodelodanfe = this.http.get(`${getUrlCad()}/nfemodelodanfe`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let caixaconfigbalanca = this.http.get(`${getUrlCad()}/caixaconfigbalanca`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo}))));
        let caixaimpressora = this.http.get(`${getUrlCad()}/caixaimpressora`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        let caixaTipoTef = this.http.get(`${getUrlCad()}/caixaTipoTef`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        let caixaPaginaCodImpressora = this.http.get(`${getUrlCad()}/caixaPaginaCodImpressora`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let caixaBalancaHandShaking = this.http.get(`${getUrlCad()}/caixaBalancaHandShaking`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let caixaBalancaModelo  = this.http.get(`${getUrlCad()}/caixaBalancaModelo`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let caixaBalancaParity  = this.http.get(`${getUrlCad()}/caixaBalancaParity`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let caixaBalancaDataBits  = this.http.get(`${getUrlCad()}/caixaBalancaDataBits`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Nome}))));
        let caixaBalancaStopBits  = this.http.get(`${getUrlCad()}/caixaBalancaStopBits`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let caixaBalancaBaudRate  = this.http.get(`${getUrlCad()}/caixaBalancaBaudRate`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Nome}))));
        return forkJoin(
            [
                contacaixa, nfeindicadorpresenca, nfemodelodanfe, caixaconfigbalanca, caixaimpressora, caixaTipoTef, caixaPaginaCodImpressora,
                 caixaBalancaHandShaking, caixaBalancaModelo, caixaBalancaParity, caixaBalancaDataBits, caixaBalancaStopBits, caixaBalancaBaudRate])
    }

    mudarStatusPedido() {
        let status = this.http.get(`${getUrlCad()}/statuspedido`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Sigla}))));
        return forkJoin([status, ])
    }

    mudarSubstituirvendedor() {
        let vendedor = this.http.get(`${getUrlCad()}/vendedor`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([vendedor, ])
    }

    importarXmlParametro() {
        let operacaoFiscal = this.http.get(`${getUrlCad()}/operacaofiscal?$expand=IdCfop`).pipe(map((res: Response) => res['value']), catchError(this.errorHandlerDefaultValues));
        let localEstoque = this.http.get(`${getUrlCad()}/localestoque`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        let naturezaFinanceira = this.http.get(`${getUrlCad()}/naturezafinanceira?$filter=(Sintetico eq false and ApareceEntrada eq true)`).pipe(map((res: Response) => res['value']), catchError(this.errorHandlerDefaultValues));
        return forkJoin([operacaoFiscal, localEstoque, naturezaFinanceira])
    }

    grupo() {
        let secao = this.http.get(`${getUrlCad()}/secao`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Nome),
            value: v.Id
        }))), catchError(this.errorHandlerDefaultValues));
        let marca = this.http.get(`${getUrlCad()}/marca`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Nome),
            value: v.Id
        }))), catchError(this.errorHandlerDefaultValues));
        let categoria = this.http.get(`${getUrlCad()}/categoria`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Nome),
            value: v.Id
        }))), catchError(this.errorHandlerDefaultValues));
        let subcategoria = this.http.get(`${getUrlCad()}/subcategoria`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Nome),
            value: v.Id
        }))), catchError(this.errorHandlerDefaultValues));
        let unidadeProduto = this.http.get(`${getUrlCad()}/unidadeproduto`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Id
        }))), catchError(this.errorHandlerDefaultValues));
        let grupoTributario = this.http.get(`${getUrlCad()}/grupotributario`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Id
        }))), catchError(this.errorHandlerDefaultValues));
        let grupo = this.http.get(`${getUrlCad()}/grupo`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Id
        }))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([secao, marca, categoria, subcategoria, unidadeProduto, grupoTributario, grupo])
    }

    subcategoria() {
        let categoria = this.http.get(`${getUrlCad()}/categoria`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([categoria])
    }

    movEstoqueDetalhe() {
        let localEstoque = this.http.get(`${getUrlPcp()}/localEstoque`).pipe(map((res: Response) => this.adidionaVazio(res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id})))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([localEstoque])
    }

    revisaoPreco() {
        let grupo = this.http.get(`${getUrlCad()}/grupo`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let fornecedor = this.http.get(`${getUrlCad()}/pessoacompleta?$filter=(Fornecedor eq true)`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let marca = this.http.get(`${getUrlCad()}/marca`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let subcategoria = this.http.get(`${getUrlCad()}/subcategoria`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let secao = this.http.get(`${getUrlCad()}/secao`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([grupo, fornecedor, marca, subcategoria, secao])
    }

    itensvendido() {
        let grupo = this.http.get(`${getUrlCad()}/grupo`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let tipoFiscal = this.http.get(`${getUrlCad()}/nfeTipoFiscal`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let marca = this.http.get(`${getUrlCad()}/marca`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let subcategoria = this.http.get(`${getUrlCad()}/subcategoria`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let secao = this.http.get(`${getUrlCad()}/secao`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let vendedor = this.http.get(`${getUrlCad()}/vendedor`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([grupo, tipoFiscal, marca, subcategoria, secao, vendedor])
    }


    produtoSimplificado() {
        let grupo = this.http.get(`${getUrlCad()}/grupo`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([grupo])
    }

    configurarNfePorItem() {
        let grupoTributario = this.http.get(`${getUrlCad()}/grupotributario`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([grupoTributario])
    }

    tributacaoIcmsUf() {
        let cstBIcms = this.http.get(`${URL_BASE}/cstbicms`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Cst}))), catchError(this.errorHandlerDefaultValues));
        let csosn = this.http.get(`${URL_BASE}/csosn`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo.toString()}))), catchError(this.errorHandlerDefaultValues));
        let modalidadebaseicms = this.http.get(`${URL_BASE}/modalidadebaseicms`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo}))), catchError(this.errorHandlerDefaultValues));
        let motivoDesoneracao = this.http.get(`${URL_BASE}/motivoDesoneracao`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.CodigoNfe === null ? null : v.CodigoNfe.toString()}))), catchError(this.errorHandlerDefaultValues));
        let modalidadeBaseIcmsSt = this.http.get(`${URL_BASE}/modalidadeBaseIcmsSt`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([cstBIcms, csosn, modalidadebaseicms, motivoDesoneracao, modalidadeBaseIcmsSt])
    }

    tributacaoIpi() {
        let operacaoFiscal = this.http.get(`${getUrlCad()}/operacaofiscal`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let grupoTributario = this.http.get(`${getUrlCad()}/grupotributario`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let cstIpi = this.http.get(`${URL_BASE}/cstipi`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo}))), catchError(this.errorHandlerDefaultValues))
        let modalidadeBaseIpi = this.http.get(`${URL_BASE}/modalidadeBaseIpi`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo}))), catchError(this.errorHandlerDefaultValues))
        // let codigoEnquadramento = this.http.get(`${URL_BASE}/codigoEnquadramento`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo}))), catchError(this.errorHandlerDefaultValues))
        return forkJoin([operacaoFiscal, grupoTributario, cstIpi, modalidadeBaseIpi, ])
    }

    tributacaoPis() {
        let operacaoFiscal = this.http.get(`${getUrlCad()}/operacaofiscal`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let grupoTributario = this.http.get(`${getUrlCad()}/grupotributario`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let cstPis = this.http.get(`${URL_BASE}/cstPis`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo}))), catchError(this.errorHandlerDefaultValues))
        return forkJoin([operacaoFiscal, grupoTributario, cstPis, ])
    }

    tributacaoCofins() {
        let operacaoFiscal = this.http.get(`${getUrlCad()}/operacaofiscal`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let grupoTributario = this.http.get(`${getUrlCad()}/grupotributario`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let cstCofins = this.http.get(`${URL_BASE}/cstCofins`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo}))), catchError(this.errorHandlerDefaultValues))
        return forkJoin([operacaoFiscal, grupoTributario, cstCofins, ])
    }

    tributacaoIss() {
        let operacaoFiscal = this.http.get(`${getUrlCad()}/operacaofiscal`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let grupoTributario = this.http.get(`${getUrlCad()}/grupotributario`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([operacaoFiscal, grupoTributario ])
    }

    tipoDocumento() {
        let contaContabil = this.http.get(`${getUrlCad()}/planocontas`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo}))));
        return forkJoin([contaContabil])
    }

    indicadorSegmento() {
        let indicadorSegmento = this.http.get(`${URL_BASE}/indicadorsegmento`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let tipoRegime = this.http.get(`${URL_BASE}/regimetributario`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([indicadorSegmento, tipoRegime ])
    }

    parametrizarSegmento() {
        let segmento = this.http.get(`${URL_BASE}/segmento`).pipe(map((res: Response) => res['value']), catchError(this.errorHandlerDefaultValues));
        return forkJoin([segmento])
    }

    configuracaoOperacaoFiscal() {
        let operacaoFiscal = this.http.get(`${getUrlCad()}/operacaofiscal`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let grupoTributario = this.http.get(`${getUrlCad()}/grupotributario`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([operacaoFiscal, grupoTributario])
    }

    grupoTributario() {
        let impostoIcms = this.http.get(`${getUrlCad()}/impostoicms`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let cstIpi = this.http.get(`${URL_BASE}/cstipi`).pipe(map((res: Response) => res['value']), catchError(this.errorHandlerDefaultValues));
        let cstCofins = this.http.get(`${URL_BASE}/cstcofins`).pipe(map((res: Response) => res['value']), catchError(this.errorHandlerDefaultValues));
        let cstPis = this.http.get(`${URL_BASE}/cstpis`).pipe(map((res: Response) => res['value']), catchError(this.errorHandlerDefaultValues));
        let cstAIcms = this.http.get(`${URL_BASE}/cstaicms`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Cst}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([impostoIcms, cstIpi, cstCofins, cstPis, cstAIcms])
    }

    producao() {
        let ordemProdLote = this.http.get(`${getUrlPcp()}/ordemprodlote`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Id), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let ordemProducao = this.http.get(`${getUrlPcp()}/ordemProducao`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let setor = this.http.get(`${getUrlCad()}/setor`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let prodCelula = this.http.get(`${getUrlPcp()}/prodCelula`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([ordemProdLote, ordemProducao, setor, prodCelula, ])
    }

    inventario() {
        let localEstoque = this.http.get(`${getUrlPcp()}/localEstoque`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([localEstoque])
    }

    transferenciaInterna() {
        let localEstoque = this.http.get(`${getUrlPcp()}/localEstoque`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([localEstoque])
    }

    transferenciaEntreFiliais() {
        let empresa = this.http.get(`${getUrlVenda()}/RelEmpresasHoldingVO`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Idpessoa}))), catchError(this.errorHandlerDefaultValues));
        let localEstoque = this.http.get(`${getUrlPcp()}/localEstoque`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([empresa, localEstoque])
    }

    fichaTecnica() {
        let prodfuncaoimobilizado = this.http.get(`${getUrlPcp()}/prodfuncaoimobilizado`).pipe(map((res: Response) => res['value']), catchError(this.errorHandlerDefaultValues));
        let prodoperacaomod = this.http.get(`${getUrlPcp()}/prodoperacaomod`).pipe(map((res: Response) => res['value']), catchError(this.errorHandlerDefaultValues));
        return forkJoin([prodoperacaomod, prodfuncaoimobilizado, ])
    }

    impostoIcms() {
        let cfop = this.http.get(`${getUrlCad()}/cfop`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Cfop}))), catchError(this.errorHandlerDefaultValues));
        let cstBIcms = this.http.get(`${URL_BASE}/cstbicms`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Cst}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([cfop, cstBIcms, ])
    }

    produtoPromocao() {
        let produto = this.http.get(`${getUrlCad()}/produto`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Nome),
            value: v.Id
        }))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([produto])
    }

    public produtoSerial() {
        let produto = this.http.get(`${getUrlCad()}/produto`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let cliente = this.http.get(`${getUrlCad()}/pessoa`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let fornecedor = this.http.get(`${getUrlCad()}/pessoa`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let usuario = this.http.get(`${getUrlCad()}/usuario`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        return forkJoin([produto, cliente, fornecedor, usuario])
    }

    public produtoComplemento() {
        let produto = this.http.get(`${getUrlCad()}/produto`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let setor = this.http.get(`${getUrlCad()}/setor`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        return forkJoin([produto, setor])
    }

    public endereco() {
        let rotaViagem = this.http.get(`${getUrlCad()}/rotaviagem`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let tipoEndereco = this.http.get(`${getUrlCad()}/tipoendereco`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        return forkJoin([rotaViagem, tipoEndereco])
    }

    public produtoCombustivel() {
        let produto = this.http.get(`${getUrlCad()}/produto`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        return forkJoin([produto])
    }

    public vendedor() {
        let pessoa = this.http.get(`${getUrlCad()}/pessoa`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        return forkJoin([pessoa])
    }

    public colaborador() {
        let cargo = this.http.get(`${getUrlCad()}/cargo`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let departamento = this.http.get(`${getUrlCad()}/setor`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let pessoa = this.http.get(`${getUrlCad()}/pessoa`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let contaContabil = this.http.get(`${getUrlCad()}/planocontas`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        return forkJoin([cargo, departamento, pessoa, contaContabil])
    }

    public cep() {
        let rota = this.http.get(`${getUrlCad()}/rotaviagem`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        return forkJoin([rota])
    }

    public contaCaixa() {
        let planocontas = this.http.get(`${getUrlCad()}/planocontas?$filter=Sintetico eq false`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        return forkJoin([planocontas])
    }

    public meiosPagamento() {
        let cartao = this.http.get(`${getUrlCad()}/cartao`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        let bandeira = this.http.get(`${getUrlCad()}/bandeira`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let tipoDocumento = this.http.get(`${getUrlCad()}/tipodocumento`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        let tipoPagamento = this.http.get(`${getUrlCad()}/tipopagamento`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo}))));
        return forkJoin([cartao, bandeira, tipoDocumento, tipoPagamento])
    }

    public novaDespesa() {
        let lancamentoPadrao = this.http.get(`${getUrlCad()}/lancamentoPadrao?$expand=IdContaDebito&$expand=IdContaCredito`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        let tipoDocumento = this.http.get(`${getUrlCad()}/tipodocumento`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        return forkJoin([lancamentoPadrao, tipoDocumento])
    }

    public cartao() {
        let bandeira = this.http.get(`${getUrlCad()}/bandeira`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id.toString()}))));
        return forkJoin([bandeira])
    }

    public tableCartaoEditarPagamentoRecebimento() {
        let cartao = this.http.get(`${getUrlCad()}/cartao`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        return forkJoin([cartao])
    }

    public condicoesPagamento() {
        let meiosPagamento = this.http.get(`${getUrlCad()}/meiospagamento`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        return forkJoin([meiosPagamento])
    }

    public modalAdicionarCondicoesPagamento() {
        let condicoesPagamento = this.http.get(`${getUrlCad()}/condicoespagamento?$expand=IdMeiosPagamento/IdAdmCartao`).pipe(map((res: Response) => res['value']), catchError(this.errorHandlerDefaultValues));
        let bandeira = this.http.get(`${getUrlCad()}/bandeira`).pipe(map((res: Response) => res['value']));
        return forkJoin([condicoesPagamento, bandeira])
    }

    public talonarioCheque() {
        let contacaixa = this.http.get(`${getUrlCad()}/contacaixa`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        return forkJoin([contacaixa])
    }

    public emitirBoleto() {
        let contacaixa = this.http.get(`${getUrlCad()}/contacaixa?$filter=(CodigoCedente ne null)`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        return forkJoin([contacaixa])
    }

    public arquivoRemessa() {
        let contacaixa = this.http.get(`${getUrlCad()}/contacaixa?$filter=Tipo eq 1`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        return forkJoin([contacaixa])
    }

    public ocorrenciaBoleto() {
        let ocorrencia = this.http.get(`${getUrlCad()}/finocorrenciaboleto`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: Util.up(v.Descricao)}))));
        return forkJoin([ocorrencia])
    }

    public dadosSelectConciliacao() {
        let contaCaixa = this.http.get(`${getUrlCad()}/contacaixa`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let naturezaFinanceira = this.http.get(`${getUrlCad()}/naturezafinanceira?$filter=(Sintetico eq false and ApareceTesouraria eq true)`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        return forkJoin([contaCaixa, naturezaFinanceira])
    }

    public planoContas() {
        let contacaixa = this.http.get(`${getUrlCad()}/contacaixa`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let plano = this.http.get(`${getUrlCad()}/plano`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        return forkJoin([contacaixa, plano])
    }

    public planoContasListagem() {
        let planoContas = this.http.get(`${getUrlCad()}/planocontas?$filter=(Sintetico eq true)`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Id
        }))));
        let contabilCodSped = this.http.get(`${getUrlCad()}/contabilCodSped`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Descricao}))));
        return forkJoin([planoContas, contabilCodSped])
    }

    public naturezaFinanceiraListagem() {
        let naturezaFinGrupo = this.http.get(`${getUrlCad()}/naturezafingrupo?$filter=(NivelDfc eq 3)`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let planoContas = this.http.get(`${getUrlCad()}/planocontas?$filter=(Sintetico eq false)`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Id
        }))));
        return forkJoin([naturezaFinGrupo, planoContas])
    }

    public modalPlanoContas() {
        let centroResultado = this.http.get(`${getUrlCad()}/centroresultado`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        let contabilDreLayout = this.http.get(`${getUrlCad()}/contabildrelayout`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        let plano = this.http.get(`${getUrlCad()}/plano`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        let planoContas = this.http.get(`${getUrlCad()}/planocontas?$filter=(Sintetico eq true)`).pipe(map((res: Response) => res['value'].map(v => ({
            label: Util.up(v.Descricao),
            value: v.Id
        }))));
        let planoContaRefSped = this.http.get(`${getUrlCad()}/planocontarefsped`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.CodSped}))));
        let contabilCodSped = this.http.get(`${getUrlCad()}/contabilCodSped`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Descricao}))));
        return forkJoin([centroResultado, contabilDreLayout, plano, planoContas, planoContaRefSped, contabilCodSped])
    }

    public modalQuitacao() {
        let contacaixa = this.http.get(`${getUrlCad()}/contacaixa?$filter=(Tipo eq 1 or Tipo eq 7)`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        return forkJoin([contacaixa])
    }

    public modalRecebimento() {
        let naturezaFinanceira = this.http.get(`${getUrlCad()}/naturezafinanceira?$filter=Sintetico eq false`).pipe(map((res: Response) => res['value']));
        let finMeioPagamento = this.http.get(`${getUrlCad()}/finmeiopagamento`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Descricao}))));
        let contacaixa = this.http.get(`${getUrlCad()}/contacaixa`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        return forkJoin([naturezaFinanceira, finMeioPagamento, contacaixa])
    }

    public pagamentoRecebimento(filtro) {
        let naturezaFinanceira = this.http.get(`${getUrlCad()}/naturezafinanceira?$filter=Sintetico eq false and ${filtro}`).pipe(map((res: Response) => res['value']));
        let finMeioPagamento = this.http.get(`${getUrlCad()}/finmeiopagamento`).pipe(map((res: Response) => res['value']));
        let contacaixa = this.http.get(`${getUrlCad()}/contacaixa`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        return forkJoin([naturezaFinanceira, finMeioPagamento, contacaixa])
    }

    public editarPagamentoRecebimento() {
        let naturezaFinanceira = this.http.get(`${getUrlCad()}/naturezafinanceira?$filter=Sintetico eq false`).pipe(map((res: Response) => res['value']));
        let finMeioPagamento = this.http.get(`${getUrlCad()}/finmeiopagamento`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Descricao}))));
        let contaCaixa = this.http.get(`${getUrlCad()}/contacaixa`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let tipoDocumento = this.http.get(`${getUrlCad()}/tipodocumento`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        let cartao = this.http.get(`${getUrlCad()}/cartao`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        return forkJoin([naturezaFinanceira, finMeioPagamento, contaCaixa, tipoDocumento, cartao])
    }

    public editarPagamentoRecebimentoContabil() {
        let planoContas = this.http.get(`${getUrlCad()}/planocontas?$filter=Sintetico eq false`).pipe(map((res: Response) => res['value']));
        return forkJoin([planoContas])
    }

    public tabelaPreco() {
        let moedas = this.http.get(`${getUrlCad()}/moedas`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        let tabelaPreco = this.http.get(`${getUrlCad()}/tabelapreco`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        return forkJoin([moedas, tabelaPreco])
    }

    public naturezaFinanceira() {
        let centroResultado = this.http.get(`${getUrlCad()}/centroresultado`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        let planoContas = this.http.get(`${getUrlCad()}/planocontas?$filter=Sintetico eq false`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        let naturezaFinanceira = this.http.get(`${getUrlCad()}/naturezafinanceira?$filter=Sintetico eq false`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        return forkJoin([centroResultado, planoContas, naturezaFinanceira])
    }

    public modalNaturezaFinanceira() {
        let centroResultado = this.http.get(`${getUrlCad()}/centroresultado?$filter=Sintetico eq false`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        let planoContas = this.http.get(`${getUrlCad()}/planocontas?$filter=Sintetico eq false`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo}))));
        let naturezaFinGrupo = this.http.get(`${getUrlCad()}/naturezafingrupo?$filter=(NivelDfc eq 3)`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let tipoDocumento = this.http.get(`${getUrlCad()}/tipodocumento`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        let projeto = this.http.get(`${getUrlCad()}/projeto`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let meiosPagamento = this.http.get(`${getUrlCad()}/finmeiopagamento`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        return forkJoin([centroResultado, planoContas, naturezaFinGrupo, tipoDocumento, projeto, meiosPagamento])
    }

    public usuario() {
        let papel = this.http.get(`${getUrlCad()}/papel`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let contaCaixa = this.http.get(`${getUrlCad()}/contacaixa`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        return forkJoin([papel, contaCaixa])
    }

    public usuarioNovo() {
        let papel = this.http.get(`${getUrlCad()}/papel`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        return forkJoin([papel])
    }

    public modalContaCaixa() {
        let planoContas = this.http.get(`${getUrlCad()}/planocontas?$filter=Sintetico eq false`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo}))));
        let banco = this.http.get(`${URL_BASE}/banco`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Codigo}))));
        return forkJoin([planoContas, banco])
    }

    public lancamentoContabilPadrao() {
        let planoContas = this.http.get(`${getUrlCad()}/planocontas?$filter=Sintetico eq false`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        let naturezaFinanceira = this.http.get(`${getUrlCad()}/naturezafinanceira?$filter=Sintetico eq false`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        let tipoDocumento = this.http.get(`${getUrlCad()}/tipodocumento`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        return forkJoin([planoContas, naturezaFinanceira, tipoDocumento])
    }

    public modalLancamentoContabilSimplificado() {
        let planoContas = this.http.get(`${getUrlCad()}/planocontas?$filter=Sintetico eq false`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        let lancamentoPadrao = this.http.get(`${getUrlCad()}/lancamentoPadrao?$expand=IdContaDebito&$expand=IdContaCredito`).pipe(map((res: Response) => res['value']));
        return forkJoin([planoContas, lancamentoPadrao])
    }

    public pedidovenda() {
        let vendedor = this.http.get(`${getUrlCad()}/vendedor`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let tabelaPreco = this.http.get(`${getUrlCad()}/tabelapreco`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let transportadora = this.http.get(`${getUrlCad()}/pessoacompleta?$filter=(Fornecedor eq true)`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([vendedor, tabelaPreco, transportadora])
    }

    public pedidovendaOriginal() {
        let vendedor = this.http.get(`${getUrlCad()}/vendedor`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let tabelaPreco = this.http.get(`${getUrlCad()}/tabelapreco`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([vendedor, tabelaPreco])
    }

    public osCadastro() {
        let vendedor = this.http.get(`${getUrlCad()}/vendedor`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let osEquipamento = this.http.get(`${getUrlCad()}/OsEquipamento`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let transportadora = this.http.get(`${getUrlCad()}/pessoacompleta?$filter=(Fornecedor eq true)`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let contrato = this.http.get(`${getUrlCad()}/contrato`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.NomeResponsavel), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([vendedor, osEquipamento, transportadora, contrato])
    }

    public devolucaoVendaLista() {
        let vendedor = this.http.get(`${getUrlCad()}/vendedor`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        return forkJoin([vendedor])
    }

    public compensacao() {
        let finMeioPagamento = this.http.get(`${getUrlCad()}/finmeiopagamento`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo}))));
        let cartao = this.http.get(`${getUrlCad()}/cartao`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        return forkJoin([finMeioPagamento, cartao])
    }

    public tableCompensacao() {
        let finMeioPagamento = this.http.get(`${getUrlCad()}/finmeiopagamento`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Descricao}))));
        let cartao = this.http.get(`${getUrlCad()}/cartao`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        return forkJoin([finMeioPagamento, cartao])
    }

    public relatorioContas(){
        let finMeioPagamento = this.http.get(`${getUrlCad()}/finmeiopagamento`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Descricao}))));
        let vendedor = this.http.get(`${getUrlCad()}/vendedor`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let indicadorOrigem = this.http.get(`${getUrlCad()}/IndicadorOrigem`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Descricao}))));
        let statusParcela = this.http.get(`${getUrlCad()}//StatusParcela`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Sigla}))), catchError(this.errorHandlerDefaultValues));
        let tipoDocumento = this.http.get(`${getUrlCad()}/tipoDocumento`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Sigla}))), catchError(this.errorHandlerDefaultValues));
        let statusBoleto = this.http.get(`${getUrlCad()}/statusBoleto`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Sigla}))));
        let contaCaixa = this.http.get(`${getUrlCad()}/contacaixa`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let bandeira = this.http.get(`${getUrlCad()}/bandeira`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));

        return forkJoin([finMeioPagamento, vendedor, indicadorOrigem, statusParcela, tipoDocumento, statusBoleto, contaCaixa, bandeira])
    }

    public devolucaoVendaCompra() {
        let natureza = this.http.get(`${getUrlCad()}/naturezafinanceira?$filter=Sintetico eq false`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        return forkJoin([natureza])
    }

    public romaneio() {
        let transportadora = this.http.get(`${getUrlCad()}/pessoacompleta?$filter=(Fornecedor eq true)`).pipe(map((res: Response) => this.adidionaVazio(res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id})))), catchError(this.errorHandlerDefaultValues));
        let rotaViagem = this.http.get(`${getUrlCad()}/rotaviagem`).pipe(map((res: Response) => this.adidionaVazio(res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id})))));
        return forkJoin([transportadora, rotaViagem])
    }

    public pedidocompra() {
        let naturezaFinanceira = this.http.get(`${getUrlCad()}/naturezafinanceira?$filter=Sintetico eq false`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        return forkJoin([naturezaFinanceira])
    }

    public agencia() {
        let banco = this.http.get(`${getUrlCad()}/banco`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        return forkJoin([banco])
    }


    parametroProducao() {
        let localEstoque = this.http.get(`${getUrlCad()}/LocalEstoque`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let setor = this.http.get(`${getUrlCad()}/setor`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let centroResultado = this.http.get(`${getUrlCad()}/centroresultado`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([localEstoque, setor, centroResultado])
    }

    parametrosCte() {
        let naturezaFinanceira = this.http.get(`${getUrlCad()}/naturezafinanceira?$filter=Sintetico eq false`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.CodControle}))));
        let cfop = this.http.get(`${getUrlCad()}/cfop`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Cfop}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([naturezaFinanceira, cfop])
    }

    pagamentoViaConta() {
        let naturezaFinanceira = this.http.get(`${getUrlCad()}/naturezafinanceira?$filter=Sintetico eq false`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        let contaCaixa = this.http.get(`${getUrlCad()}/contacaixa`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        return forkJoin([naturezaFinanceira, contaCaixa])
    }

    modalTransferencia() {
        let contaCaixa = this.http.get(`${getUrlCad()}/contacaixa`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        return forkJoin([contaCaixa])
    }

    dadosEssenciaisUm() {
        let operacaoFiscal = this.http.get(`${getUrlCad()}/operacaofiscal`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let grupo = this.http.get(`${getUrlCad()}/grupo`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let grupoBem = this.http.get(`${getUrlCad()}/patrimgrupobem`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([operacaoFiscal, grupo, grupoBem])
    }

    parametrosPedido() {
        let statusPedido = this.http.get(`${getUrlCad()}/statusPedido`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Sigla}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([statusPedido])
    }

    dadosEssenciaisDois() {
        let nfeModeloDanfe = this.http.get(`${getUrlCad()}/nfeModeloDanfe`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id.toString()}))), catchError(this.errorHandlerDefaultValues));
        let nfeTipoEmissao = this.http.get(`${getUrlCad()}/nfeTipoEmissao`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id.toString()}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([nfeModeloDanfe, nfeTipoEmissao])
    }

    parametrosContasUm() {
        let planoContas = this.http.get(`${getUrlCad()}/planocontas?$expand=IdPlano&$filter=(IdPlano eq 1)`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([planoContas])
    }

    parametrosContasDois() {
        let planoContas = this.http.get(`${getUrlCad()}/planocontas?$expand=IdPlano&$filter=(IdPlano eq 1)`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([planoContas])
    }

    parametrosNatureza() {
        let naturezaFinanceira = this.http.get(`${getUrlCad()}/naturezafinanceira?$filter=Sintetico eq false`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.CodControle}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([naturezaFinanceira])
    }

    parametrosEmpresaPrincipal() {
        let contador = this.http.get(`${getUrlCad()}/contador`).pipe(map((res: Response) => this.adidionaVazio(res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id})))), catchError(this.errorHandlerDefaultValues));
        let planoContas = this.http.get(`${getUrlCad()}/plano`).pipe(map((res: Response) => this.adidionaVazio(res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id})))));
        return forkJoin([contador, planoContas])
    }

    parametrosFinanceirosGeral() {
        let contacaixa = this.http.get(`${getUrlCad()}/contacaixa`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))));
        let cartao = this.http.get(`${getUrlCad()}/cartao`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))));
        return forkJoin([contacaixa, cartao])
    }

    parametrosEmpresaSped() {
        let spedObribIcmsRecol = this.http.get(`${URL_BASE}/spedobribicmsrecol`).pipe(map((res: Response) => this.adidionaVazio(res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id})))), catchError(this.errorHandlerDefaultValues));
        let spedCodReceita = this.http.get(`${URL_BASE}/spedcodreceita`).pipe(map((res: Response) => this.adidionaVazio(res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id})))), catchError(this.errorHandlerDefaultValues));
        let spedPerfil = this.http.get(`${URL_BASE}/spedperfil`).pipe(map((res: Response) => this.adidionaVazio(res['value'].map(v => ({label: Util.up(v.Nome), value: v.Nome})))), catchError(this.errorHandlerDefaultValues));
        let spedIndicadorAtividade = this.http.get(`${getUrlCad()}/spedindicadoratividade`).pipe(map((res: Response) => this.adidionaVazio(res['value'].map(v => ({label: Util.up(v.Nome), value: v.Codigo})))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([spedObribIcmsRecol, spedCodReceita, spedPerfil, spedIndicadorAtividade])
    }

    parametrosEmpresaSpedContribuicao() {
        let spedIndicadorAtividade = this.http.get(`${getUrlCad()}/spedindicadoratividade`).pipe(map((res: Response) => this.adidionaVazio(res['value'].map(v => ({label: Util.up(v.Nome), value: v.Codigo})))), catchError(this.errorHandlerDefaultValues));
        let spedPisMetodoApropCredito = this.http.get(`${getUrlCad()}/spedpismetodoapropcredito`).pipe(map((res: Response) => this.adidionaVazio(res['value'].map(v => ({label: Util.up(v.Nome), value: v.Codigo})))), catchError(this.errorHandlerDefaultValues));
        let spedPisCriterioApuracao = this.http.get(`${getUrlCad()}/spedpiscriterioapuracao`).pipe(map((res: Response) => this.adidionaVazio(res['value'].map(v => ({label: Util.up(v.Nome), value: v.Codigo})))), catchError(this.errorHandlerDefaultValues));
        let spedPisIndTipoEscritura = this.http.get(`${getUrlCad()}/spedpisindtipoescritura`).pipe(map((res: Response) => this.adidionaVazio(res['value'].map(v => ({label: Util.up(v.Nome), value: v.Codigo})))), catchError(this.errorHandlerDefaultValues));
        let spedPisNaturezaJuridica = this.http.get(`${getUrlCad()}/spedpisnaturezajuridica`).pipe(map((res: Response) => this.adidionaVazio(res['value'].map(v => ({label: Util.up(v.Nome), value: v.Codigo})))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([spedIndicadorAtividade, spedPisMetodoApropCredito, spedPisCriterioApuracao, spedPisIndTipoEscritura, spedPisNaturezaJuridica])
    }


    parametrosOs() {
        let osSegmento = this.http.get(`${getUrlCad()}/ossegmento`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Nome), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([osSegmento])
    }

    parametrosEmpresaInscricaoST() {
        let spedCodReceita = this.http.get(`${URL_BASE}/spedcodreceita`).pipe(map((res: Response) => this.adidionaVazio(res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo})))), catchError(this.errorHandlerDefaultValues));
        let spedObribIcmsRecol = this.http.get(`${URL_BASE}/spedobribicmsrecol`).pipe(map((res: Response) => this.adidionaVazio(res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Codigo})))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([spedCodReceita, spedObribIcmsRecol])
    }

    modalConfigurarNfe() {
        let grupoTributario = this.http.get(`${getUrlCad()}/grupotributario`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        let operacaoFiscal = this.http.get(`${getUrlCad()}/operacaofiscal`).pipe(map((res: Response) => res['value'].map(v => ({label: Util.up(v.Descricao), value: v.Id}))), catchError(this.errorHandlerDefaultValues));
        return forkJoin([grupoTributario, operacaoFiscal])
    }

    private errorHandlerDefaultValues(error: HttpErrorResponse) {
        console.log(error)
        if(error instanceof HttpErrorResponse) {
            let excecao
            try {
                if (error.error && error.error['error']) {
                    excecao = error.error.error.message
                } else if(error.message) {
                    excecao = error.message
                } else {
                    excecao = JSON.stringify(error)
                }
            } catch (e) {
                excecao = 'Feche o sistema e acesse novamente e confira se o registro foi gravado'
            }
            return excecao
        }
        return of([]);
    }

    private errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || error.error.error.message)
    }


    adidionaVazio(v) {
        const vazio = {label: '-', value: null}
        v.unshift(vazio)
        return v
    }

}
