<div class="p-grid">
    <div  style="margin-top: 30px;">
        <div class="p-col-6 p-offset-3">
            <div>Pesquisar Empresa</div>
            <div style="margin-bottom: 5px;">
                <input class="p-col-11" pInputText [(ngModel)]="filtro">
                <button class="p-col-1" pButton icon="fa fa-search"></button>
            </div>
            <p-table [paginator]="true" [rows]="qtdLinhas || 7" [totalRecords]="empresas.length" [value]="empresas" [lazy]="false">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th [ngStyle]="{width: '82%'}" > Empresa</th>
                        <th [ngStyle]="{width: '15%'}" > </th>
                        <th [ngStyle]="{width: '12%'}" > Ações</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td>{{rowData.RAZAO_SOCIAL}}</td>
                        <td><button pButton label="Acessar" (click)="quartaAuthenticacao(rowData)"></button></td>
                        <td>
                            <app-opcoes-table [value]="rowData"
                                            [opcoes]="opcoesTable"></app-opcoes-table>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
